export enum ProfessionalismStatus {
  UNDETERMINED = 'UNDETERMINED',
  EXPERIENCED = 'EXPERIENCED',
  INEXPERIENCED = 'INEXPERIENCED',
  PENDING_REQUEST = 'PENDING_REQUEST',
  REQUEST_REJECTED = 'REQUEST_REJECTED',
  REQUEST_ACCEPTED = 'REQUEST_ACCEPTED',
}

export enum QuestionType {
  CHECKBOX = 'checkbox',
  TEXT = 'text',
}
